/* ====== TO TOP ====== */
jQuery(document).ready(function ($) {
    var offset = 100,
            offset_opacity = 1200,
            scroll_top_duration = 700,
            $back_to_top = $('.cd-top');
    $(window).scroll(function () {
        ($(this).scrollTop() > offset) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
        if ($(this).scrollTop() > offset_opacity) {
            $back_to_top.addClass('cd-fade-out');
        }
    });
    $back_to_top.on('click', function (event) {
        event.preventDefault();
        $('body,html').animate({
            scrollTop: 0
        }, scroll_top_duration
                );
    });
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
});


$('.search-rb').on('click', function (e) {
//    e.preventDefault()
//    console.log($(this)[0].checked)
})

$('#logout-item').on('click', function () {
    $(this).closest('form').submit();
})


$('input[name=logintype]').on('change', function () {
    var chosen_type = $(this).val()

    if (chosen_type === 'SAVENCIA COLLABORATOR') {
        $('#auth_params').removeClass('revealed').addClass('hidden')
        $('#auth_params_hr').hide()
        $('.auth-input').prop('required', false)
    }
    if (chosen_type === 'EXTERNAL USER') {
        $('#auth_params').removeClass('hidden').addClass('revealed')
        $('#auth_params_hr').show()
        $('.auth-input').prop('required', true)
    }
})

$('#loginform').on('submit', function (e) {
    var chosen_type = $('input[name=logintype]:checked').val()
console.log(chosen_type);
//return false;
    if (chosen_type === 'SAVENCIA COLLABORATOR') {
        window.location.href = $('#btn_send').data('sso');
        return false;
    }

    return true;
})